<script lang="ts" setup>
const navLinkGroups = [
  {
    name: 'Allgemein',
    links: [
      {
        name: 'Dashboard',
        href: '/admin',
        icon: 'i-ph-house',
      },
      {
        name: 'Angebotsarten und Kunstformen',
        href: '/admin/angebotsarten-und-kunstformen',
        icon: 'i-ph-archive',
      },
      {
        name: 'Medien',
        href: '/admin/medien',
        icon: 'i-ph-image',
      },
      {
        name: 'Startseite',
        href: '/admin/landing/homepage',
        icon: 'i-ph-browser',
      },
      {
        name: 'Datenschutzerklärung',
        href: '/admin/landing/datenschutz',
        icon: 'i-ph-shield-check',
      },
      {
        name: 'AGB',
        href: '/admin/landing/agb',
        icon: 'i-ph-list-numbers-fill',
      },
      {
        name: 'Nutzungsbedingungen',
        href: '/admin/landing/nutzungsbedingungen',
        icon: 'i-ph-scroll-bold',
      },
      {
        name: 'Impressum',
        href: '/admin/landing/impressum',
        icon: 'i-ph-list-fill',
      },
    ],
  },
  {
    name: 'Daten',
    links: [
      {
        name: 'Regionen',
        href: '/admin/regionen',
        icon: 'i-ph-map-trifold',
      },
      {
        name: 'Kunstszene',
        href: '/admin/kunstraeume',
        icon: 'i-ph-map-pin',
      },
      {
        name: 'Künstler:innen',
        href: '/admin/kuenstler-innen',
        icon: 'i-ph-users-three',
      },
      {
        name: 'Angebote',
        href: '/admin/angebote',
        icon: 'i-ph-currency-eur',
      },
      {
        name: 'Buchungen',
        href: '/admin/angebots-buchungen',
        icon: 'i-ph-book-bookmark-fill',
      },
    ],
  },
]

const marker = useMarkerIO()
const supabase = useSupabase()
</script>

<template>
  <div class="flex h-screen w-60 flex-col gap-y-5 overflow-y-auto p-2 pr-0">
    <AlLink :to="{ name: 'index' }" class="flex h-16 shrink-0 items-center gap-4 px-3">
      <UIcon name="i-ph-arrow-circle-left-fill" class="h-8 w-8 text-al-primary-500" />
      <img src="/logo_with_subtitle.png" alt="atelierluft Logo" class="w-32" />
    </AlLink>
    <nav class="flex flex-1 flex-col px-4">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li v-for="group of navLinkGroups" :key="group.name">
          <div class="text-xs font-semibold leading-6 text-stone-400">{{ group.name }}</div>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="link in group.links" :key="link.name">
              <UButton :to="link.href" variant="link" :icon="link.icon" :label="link.name" />
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="flex-1" />
    <UButton
      label="Feedback geben"
      icon="i-ph-hand-heart-fill"
      size="xl"
      color="purple"
      @click="() => marker.capture('fullscreen')"
    />
    <UButton
      label="Abmelden"
      icon="i-ph-sign-out"
      size="xl"
      variant="ghost"
      @click="() => supabase.auth.signOut()"
    />
  </div>
</template>
