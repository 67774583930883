<template>
  <div class="flex min-h-full bg-stone-200">
    <div>
      <AlAdminSidebar class="sticky top-0" />
    </div>
    <div class="m-2 flex flex-1 rounded-md bg-stone-50 shadow-md">
      <slot />
    </div>
  </div>
</template>
